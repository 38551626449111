import { GrowthBook } from "@growthbook/growthbook";

import type { Attributes } from "@/lib/ab-testing/service/attributes";

import { captureAbTestingException } from "@/lib/ab-testing/client.ts";
import { addAbTestEvent } from "@/lib/ab-testing/service/analytics";
import * as attributes from "@/lib/ab-testing/service/attributes";
import {
  splitUrlFeature,
  splitUrlMapFeature,
} from "@/lib/ab-testing/service/features";
import { addSentryProject } from "@/lib/integrations/sentry/client";

function getAttributes(): Attributes {
  return {
    solvariSession: attributes.solvariSession(),
    solvariClientId: attributes.solvariClientId(),
    url: attributes.url(),
    urlFull: attributes.urlFull(),
    urlPath: attributes.urlPath(),
    urlDomain: attributes.urlDomain(),
    siteLocale: attributes.siteLocale(),
    screenWidth: attributes.screenWidth(),
    hasForm: attributes.hasForm(),
    formId: attributes.formId(),
    formGroupId: attributes.formGroupId(),
    siteType: attributes.siteType(),
    expertSiteTheme: attributes.expertSiteTheme(),
  };
}

async function initAbTesting(context?: {
  attributes?: Partial<Attributes> & Record<string, unknown>;
  features?: (() => void)[];
}) {
  const growthBook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: "sdk-QSCBGOnGSKSAX05C",
    attributes: { ...getAttributes(), ...context?.attributes },
    trackingCallback: addAbTestEvent, // When a feature gets triggered it will send an event to gtm and Argus
  });

  addSentryProject({
    project: "mkt-ab-testing",
    addData() {
      return {
        contexts: {
          attributes: growthBook.getAttributes() as Record<string, unknown>,
          features: growthBook.getFeatures() as Record<string, unknown>,
        },
      };
    },
  });

  try {
    await growthBook.init({ streaming: false });
  } catch (error) {
    captureAbTestingException(error);
  }

  // Bind it to the window so that other apps can check for features without a second instance
  window.abTesting = growthBook;

  try {
    splitUrlFeature();
    splitUrlMapFeature();
    context?.features?.forEach((feature) => feature());
  } catch (error) {
    captureAbTestingException(error);
  }

  // Renders the page in case it was hidden by an anti flicker snippet
  if (window.abTestingRender) {
    window.abTestingRender();
  }
}

export { getAttributes, initAbTesting };
